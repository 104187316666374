import React, { useState, useEffect } from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../../images/logo.png";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useFirebaseApp, useFirestore, useUser } from "reactfire";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { useForm } from "react-hook-form";
import { navigate } from "gatsby-link";
import Loader from "../../components/Loader";
import ImageUploading from "react-images-uploading";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

const Admin = ({ location }) => {
  const firebase1 = useFirebaseApp();
  const firestore = useFirestore(firebase1);
  const firebaseStorage = firebase1.storage();
  const fieldValue = useFirestore.FieldValue;

  const [signedIn, setSignedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  let admin = false;
  let signedIn1 = false;

  const { data: user } = useUser();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [product, setProduct] = useState({
    category: "",
    name: "",
    dimensions: "",
    finishes: "",
    description: "",
    imageUrl: "",
    error: "",
    enabled: true,
  });

  const [selectedImages, setSelectedImages] = useState([]);
  const [imagesToUpload, setImagesToUpload] = useState([]);
  const [isUploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState("");

  let isEditPage = false;
  let productData = {};

  const signOut = () => {
    window.localStorage.setItem("isSignedIn", false);
    navigate("/Login", { state: { from: "signOut" } });
  };

  useEffect(() => {
    if (
      window.localStorage.getItem("isSignedIn") != undefined &&
      window.localStorage.getItem("isSignedIn") == "true"
    ) {
      // console.log("User Object: " + JSON.stringify(user));
      // setSignedIn(true);
      // signedIn = true;

      signedIn1 = true;

      getFirestoreData(user);
    } else {
      navigate("/Login");
    }

    // console.log("Useeffect1 ke andar: "+admin);
  }, [user, window.localStorage.getItem("isSignedIn")]);

  if (location.state != null || location.state != undefined) {
    if (location.state.isFromEdit && location.state.productData) {
      isEditPage = true;
      productData = location.state.productData;
    } else {
      isEditPage = false;
    }
  }

  // console.log("Location Props: " + JSON.stringify(location.state.productData));

  const getFirestoreData = async (user) => {
    if (user != undefined) {
      await firestore
        .collection("Users")
        .where("email", "==", user.email)
        .get()
        .then((snapshot) => {
          if (snapshot.docs[0].data().admin != undefined) {
            // setIsAdmin(true)
            // isAdmin = true
            admin = true;
            // console.log("snapshot ki condtion: true");
          } else {
            // setIsAdmin(false);
            // isAdmin = false;
            admin = false;
            navigate("/Login");
            // console.log("snapshot ki condtion: false");
          }
        });

      setSignedIn(signedIn1);
      setIsAdmin(admin);
    } else {
      return <Loader />;
    }
  };

  const imageChangeHandler = (e) => {
    //   console.log(e.target.files);
    console.log(e.target.files);

    if (e.target.files) {
      const imagesArray = Array.from(e.target.files);

      setImagesToUpload((uploadImages) => uploadImages.concat(imagesArray));

      const imagesArrayAsBlob = Array.from(e.target.files).map((image) =>
        URL.createObjectURL(image)
      );

      // console.log("Images Array: " + getImageAsUrl);

      setSelectedImages((prevImages) => prevImages.concat(imagesArrayAsBlob));
    }
  };

  const renderImages = (source) => {
    return source.map((image) => {
      return (
        <div className="uplode_card">
          <img width="80px" height="80px" src={image} key={image}></img>
        </div>
      );
    });
  };

  const [images, setImages] = React.useState([]);

  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);

    setImages(imageList);

    const allImages = Array.from(imageList).map((image) => image.data_url);

    // console.log("Images Array: "+JSON.stringify(allImages));
    setImagesToUpload(allImages);
  };

  const onSubmit = async (data, e) => {
    console.log("Data: " + JSON.stringify(data));
    e.preventDefault();

    const productsRef = firestore.collection("Products").doc();

    //Get image's storage download url and put it into the database
    await productsRef
      .set({
        category: data.categories,
        name: data.name,
        dimensions: data.dimensions,
        finishes: data.finishes,
        description: data.description,
        imagesUrl: [],
        timestamp: fieldValue.serverTimestamp(),
        enabled: true,
      })
      .then(() => {
        setUploading(true);

        uploadImagesToStorage(productsRef);

        // console.log("Images to upload:"+JSON.stringify(imagesToUpload));

        // map.set("main", result2);

        // console.log("Main Map: "+JSON.stringify(map.get("main")));

        //   productsRef
        //     .update({ imagesUrl: fieldValue.arrayUnion(result2) })
        //     .then(() => {});

        // uploadTask.on("state_changed", (snapshot) => {
        //   firebaseStorage
        //     .ref("productImages")
        //     .child(imageToUpload.name)
        //     .getDownloadURL()
        //     .then((url) => {
        //       productsRef
        //         .update({ imagesUrl: fieldValue.arrayUnion(url) })
        //         .then(() => {
        //           const uploadProgress = Math.round(
        //             (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        //           );

        //           // setUploadProgress(uploadProgress)
        //           if (uploadProgress == 100) {
        //             setUploading(false);
        //           } else {
        //             setUploading(true);
        //           }
        //           console.log("Upload State2: Running" + uploadProgress);
        //         });

        //       console.log(
        //         "Upload State1: Running" + JSON.stringify(snapshot)
        //       );

        //       // productsRef
        //       //   .collection("Images")
        //       //   .doc()
        //       //   .set({ imageUrl: url })
        //       //   .then(() => {
        //       //     if(imagesToUpload.length - 1 == index){
        //       //       setUploading(false);
        //       //       alert("Product Added!");
        //       //     }

        //       //   });
        //     });
        //   console.log("Upload State3: Running");
        // });

        // uploadTask.on("state_changed", (snapshot) => {
        //   firebaseStorage
        //     .ref("productImages")
        //     .child(imageToUpload.name)
        //     .getDownloadURL()
        //     .then((url) => {
        //       productsRef
        //         .update({ imagesUrl: fieldValue.arrayUnion(url) })
        //         .then(() => {
        //           const uploadProgress = Math.round(
        //             (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        //           );

        //           // setUploadProgress(uploadProgress)
        //           if (uploadProgress == 100) {
        //             setUploading(false);
        //           } else {
        //             setUploading(true);
        //           }
        //           console.log("Upload State2: Running" + uploadProgress);
        //         });

        //       console.log(
        //         "Upload State1: Running" + JSON.stringify(snapshot)
        //       );

        //       // productsRef
        //       //   .collection("Images")
        //       //   .doc()
        //       //   .set({ imageUrl: url })
        //       //   .then(() => {
        //       //     if(imagesToUpload.length - 1 == index){
        //       //       setUploading(false);
        //       //       alert("Product Added!");
        //       //     }

        //       //   });
        //     });
        //   console.log("Upload State3: Running");
        // });
      })
      .catch((error) => {
        alert(error);
        setUploading(false);
      });

    // console.log("Upload State6: Running");

    // console.log("Error: " + JSON.stringify(e));
  };

  const imagesUrlArray = [];

  const uploadImagesToStorage = async (productsRef) => {
    //Start uploading image to firebase storage
    if (imagesToUpload.length > 0) {
      // console.log("Array Size1" + imagesToUpload.length);

      const getDate = new Date();
      const getMiliseconds = getDate.getTime();

      imagesToUpload.map((imageToUpload, index) => {
        const storageRef = firebaseStorage.ref(
          `/productImages/${getMiliseconds}-${index}`
        );

        console.log("Index: " + index);

        var metadata = {
          customMetadata: {
            main: false,
          },
        };

        if (index == 0) {
          metadata = {
            customMetadata: {
              main: true,
            },
          };
        }

        storageRef
          .putString(imageToUpload, "data_url", metadata)
          .then((result1) => {
            console.log("Upload to Storage: " + JSON.stringify(result1));

            const uploadProgress = Math.round(
              (result1.bytesTransferred / result1.totalBytes) * 100
            );

            // setUploadProgress(uploadProgress);

            if (uploadProgress == 100) {
              // console.log("Images Map: "+JSON.stringify(map));
              console.log(
                "Uploading index: " +
                index +
                " Array length: " +
                imagesToUpload.length
              );
              setUploading(false);

              // navigate("/admin/")
            } else {
              setUploading(true);
            }

            updateDownloadUrl(storageRef).then((imagesUrlArray1) => {
              productsRef
                .update({ imagesUrl: imagesUrlArray1 })
                .then((result) => {
                  console.log("Hurrraaaaaaaaay!!!! " + JSON.stringify(result));
                  navigate("/admin/");
                });

              // const reversedArray = imagesUrlArray1.reverse();
              // console.log("Get All Urls: " + JSON.stringify(imagesUrlArray1));
            });

            // setUploading(true);
            console.log("Upload State2: Running" + uploadProgress);
          });
      });
    } else {
      setUploading(false);
    }
  };

  const updateDownloadUrl = async (storageRef) => {
    return new Promise((resolve, reject) => {
      storageRef.getMetadata().then((metadata) => {
        storageRef
          .getDownloadURL()
          .then((result2) => {
            console.log("Get Download Url: " + result2);

            console.log(
              "Metadata: " +
              metadata.customMetadata.main +
              " Download Url: " +
              result2
            );

            if (metadata.customMetadata.main) {
              imagesUrlArray.splice(0, 0, result2);
            } else {
              imagesUrlArray.push(result2);
            }

            console.log("Images Url Array: " + JSON.stringify(imagesUrlArray));
            resolve(imagesUrlArray);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    });
  };

  const handleChange = (e) => {
    console.log("PRoduct Name: ");
    setProduct({ ...product, [e.target.name]: e.target.value, error: "" });
  };
  if (signedIn) {
    return (
      <div className="admin2">
        <div className="admin_header">
          {/* <Container> */}
          <Row>
            <Col md="4">
              <div className="admin_logo">
                <img src={Logo} alt="img" />
              </div>
            </Col>
            <Col md="4">
              <div></div>
            </Col>
            <Col md="4">
              <div className="admin_text">
                <p onClick={() => signOut()}>Log Out</p>
              </div>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
        <Row>
          <Col md="2">
            <div className="left_section">
              <div>
                <ul>
                  <li>
                    <a href="/admin/">Listed Products</a>
                  </li>
                  <li>
                    <a href="/admin/AddedProduct/">Add a Product</a>
                  </li>
                  <li>
                    <a href="/admin/NewRegisters/">Users</a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col md="10">
            <section className="right_section">
              <div>ADD PRODUCT</div>
              <Row>
                <Col md="6">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    {/* <FormGroup>
                    <Label>Product List</Label>
                    <Input
                      type="text"
                      name="ProductList"
                      onChange={handleChange}
                      {...register("category")}
                      placeholder="Product List"
                    />
                  </FormGroup> */}
                    <FormGroup>
                      <Label>Product List</Label>
                      <select
                      
                        type="select"
                        name="categories"
                        onChange={handleChange}
                        {...register("categories", {
                          required: true,
                        })}
                      >
                        <option value="Select a category">
                          Select a category
                      </option>
                        <option value="Tables & Ottomans">
                          Tables & Ottomans
                      </option>
                        <option value="TV & Media Units">TV & Media Units</option>
                        <option value="Chairs & Arm Chairs">
                          Chairs & Arm Chairs
                      </option>
                        <option value="Sofas">Sofas</option>
                        <option value="Shelves & Storages">
                          Shelves & Storages
                      </option>
                        <option value="Benches">Benches</option>
                        <option value="Lightings">Lightings</option>
                        <option value="Home Decor">Home Decor</option>
                        <option value="Accessories">Accessories</option>
                      </select>
                      {errors.enquiry &&
                        errors.categories.type === "required" && (
                          <p className="errorMsg">Please select a category.</p>
                        )}
                    </FormGroup>
                    <FormGroup>
                      <Label>Product Name</Label>
                      <input
                        type="text"
                        name="name"
                        onChange={() => handleChange()}
                        {...register("name")}
                        placeholder="Product Name"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Product SKU / Dimensions</Label>
                      <textarea
                        className="diminesions_h"
                        type="textarea"
                        name="Diminesions"
                        onChange={handleChange}
                        {...register("dimensions")}
                        placeholder="Diminesions"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Finishes</Label>
                      <textarea
                        className="diminesions_h"
                        type="textarea"
                        name="Finishes"
                        onChange={handleChange}
                        {...register("finishes")}
                        placeholder="Finishes"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Description</Label>
                      <textarea
                        className="diminesions_h2"
                        type="textarea"
                        name="Description"
                        onChange={handleChange}
                        {...register("description")}
                        placeholder="Description"
                      />
                    </FormGroup>

                    {isUploading ? (
                      <Button className="uplode_btn" disabled>
                        Adding Product...
                    </Button>
                    ) : (
                        <Button className="uplode_btn" type="submit">
                          Add Product Now
                    </Button>
                      )}
                  </Form>
                </Col>
                <Col md="6">
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    resolutionType="absolute"
                    resolutionWidth={2000}
                    resolutionHeight={2000}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      errors,
                    }) => (
                        <div className="upload__image-wrapper">
                          <button
                            className="upload-image-btn"
                            onClick={onImageUpload}
                          >
                            Upload Images
                      </button>
                          &nbsp;
                      <button
                            className="remove-all-images-btn"
                            onClick={onImageRemoveAll}
                          >
                            Remove all images
                      </button>
                          <br />
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              {index == 0 ? <h5>Main Image</h5> : index + 1}
                              <img src={image.data_url} alt="" width="80px" />
                              <div className="image-item__btn-wrapper">
                                <button
                                  className="change-image-btn"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  Change Image
                            </button>
                                <button
                                  className="remove-image-btn"
                                  onClick={() => onImageRemove(index)}
                                >
                                  Remove
                            </button>
                              </div>
                            </div>
                          ))}
                          {errors && (
                            <div style={{ color: "black" }}>
                              {errors.maxNumber && (
                                <span>
                                  Number of selected images exceed maxNumber
                            </span>
                              )}
                              {errors.acceptType && (
                                <span>Your selected file type is not allow</span>
                              )}
                              {errors.maxFileSize && (
                                <span>Selected file size exceed maxFileSize</span>
                              )}
                              {errors.resolution && (
                                <span>
                                  The image should have a resolution of 2000 x 2000.
                            </span>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                  </ImageUploading>

                  {/* {renderImages(selectedImages)}

                <Input
                  type="file"
                  name="uploadImage"
                  className="uplode_btn"
                  multiple
                  {...register("uploadImage")}
                  onChange={imageChangeHandler}
                  placeholder="UPLOAD PRODUCT IMAGE"
                /> */}
                </Col>
              </Row>
              {console.log("Progress: " + uploadProgress)}
              {uploadProgress > 0 && uploadProgress < 100 ? (
                <h4>Uploading: {uploadProgress}</h4>
              ) : (
                  ""
                )}
            </section>
          </Col>
        </Row>

      </div>
    );
  } else {
    return null;
  }
};

export default Admin;
